(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.HeaderFunctions = {

        header : $('.o-header'),
        mobileMenu : $('.o-mobile-nav-primary'),


        setup: function(){
            this.stickyHeader();
            this.headerToggle();
            this.mobileSubMenu();
        },
        headerToggle: function(){
            window.HeaderFunctions.header.on('click','.j-menu-toggle', function(){
                $('body').toggleClass('is-active__menu');
                $(this).toggleClass('is-active');
            });
        },
        mobileSubMenu: function(){
            window.HeaderFunctions.mobileMenu.find('.sub-menu').siblings('a').append('<span class="menu-slide"></span>');
            window.HeaderFunctions.mobileMenu.on('click','a', function(e){
                var _this = $(this);
                if(_this.siblings('.sub-menu').length > 0){
                    e.preventDefault();
                    e.stopPropagation();
                    _this.siblings('.sub-menu').stop(true, true).slideToggle(200).closest('.menu-item').toggleClass('menu__is-open').find('.menu-slide').toggleClass('open');
                }
            });
        },
        stickyHeader: function(){
            // Sticky Header
            function checkHeaderPosition(){
                var scroll = $(window).scrollTop();
                if (scroll >= 100) {
                    window.HeaderFunctions.header.addClass('o-header--sticky is_sticky');
                } else {
                    if (scroll <= 5) {
                        window.HeaderFunctions.header.removeClass('o-header--sticky is_sticky');
                    }
                }
            }
            checkHeaderPosition();
            //caches a jQuery object containing the header element
            $(window).scroll(function() {
                checkHeaderPosition();
            });
        },

    };

}(window.jQuery, window, document)); // End of jQuery wrapper

