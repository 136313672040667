/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				AOS.init({
					once: true,
					duration: 1000,
				});
				// 
				jQuery(window).on('load', function(){		
					AOS.refresh();	

					$('select').each(function(){
						var _this = $(this);
						if(_this.val()){
							_this.removeClass('placeheld');
						}else{
							_this.addClass('placeheld');
						}
					});
				});
				
				// JavaScript to be fired on all pages
				window.HeaderFunctions.setup();
				window.blockFunctions.setup();

				$('body').on('change','select',function(){
					var _this = $(this);
					if(_this.val()){
						_this.removeClass('placeheld');
					}else{
						_this.addClass('placeheld');
					}
				});
			

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

				var tabs = $('.g-tabbed');
				if(tabs.length > 0 ){
					tabs.each(function(){
						var tab = $(this);
						var tabSlider = tab.find('.g-tabbed--page').slick();
						
						tab.on('click', '.g-tabbed--tab', function(){
							tabSlider.slick('slickGoTo', $(this).data('tab') );

							$(this).addClass('active').siblings().removeClass('active');
						});
					});
				}
				
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
