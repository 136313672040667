(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.blockFunctions = {

        setup: function(){
            this.slick();
            this.faqs();
            // this.parallaxImages();
        },

        slick: function(){
            var slick = $('.j-slick');
            if(slick.length > 0){
                slick.slick();
                $(window).on('resize', function() {
                    slick.each(function(){
                        if (!$(this).hasClass('slick-initialized')){
                            $(this).slick();
                        }
                    });
                });
            }
        },
        faqs: function(){
            var faqs = $('.j-faqs');
            if(faqs.length > 0 ){
                faqs.on('click','.j-faqs--toggle', function(){
                    $(this).parents('.j-faqs--single').toggleClass('is_active').find('.j-faqs--answer').slideToggle();
                });
                faqs.on('click','.j-toggle--button', function(){
                    $('.is_active').removeClass('is_active').find('.j-faqs--answer').hide();
                });
            }
        },
        parallaxImages: function(){
            var controller = new ScrollMagic.Controller();
            var triggers = $('.j-parallax-image');
            triggers.each(function(index){
                trigger = triggers.get(index);
                var tweenOut = new TimelineMax()
                .add([
                    TweenMax.fromTo(trigger, 1, {'transform': 'translateY(20px)' }, {'transform': 'translateY(250px)' })
                ]);
                var sceneOut = new  ScrollMagic.Scene({ triggerElement: trigger, duration: 300 })
                .setTween(tweenOut)
                // .addIndicators()
                .addTo(controller);
                console.log(tweenOut);

                // var tweenIn = new TimelineMax()
                // .add([
                //     TweenMax.fromTo(trigger, 1, {'transform': 'translateY(300px)' }, {'transform': 'translateY(0)' })
                // ]);
                // var sceneIn = new  ScrollMagic.Scene({ triggerElement: trigger, duration: 300, triggerHook: 1  })
                // .setTween(tweenIn)
                // .addIndicators()
                // .addTo(controller);
            });
            console.log(controller);
        }
    };
}(window.jQuery, window, document)); // End of jQuery wrapper